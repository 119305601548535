.cookies,
.cookies * {
  box-sizing: border-box;
}

.cookies {
  background-color: var(--a-cookieBannerWidget-wrapper-background-color);
  color: #FFFFFF;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  line-height: 21px;
  z-index: 90;
  opacity: 1;
  -webkit-transition: opacity 1s ease;
  -ms-transition: opacity 1s ease;
  transition: opacity 1s ease;
  max-height: calc(100vh - var(--header--height, 10vh));
  overflow-y: auto;
}
