.cookiesMainText {
  font-family: var(--a-cookieBannerWidget-text-font-family);
  font-weight: var(--a-cookieBannerWidget-text-font-weight);
  font-size: var(--a-cookieBannerWidget-text-font-size);
  color: var(--a-cookieBannerWidget-text-color);

  margin-top: var(--a-cookieBannerWidget-text-margin-top);
  margin-right: var(--a-cookieBannerWidget-text-margin-right);
  margin-bottom: var(--a-cookieBannerWidget-text-margin-bottom);
  margin-left: var(--a-cookieBannerWidget-text-margin-left);

  padding-top: var(--a-cookieBannerWidget-text-padding-top);
  padding-right: var(--a-cookieBannerWidget-text-padding-right);
  padding-bottom: var(--a-cookieBannerWidget-text-padding-bottom);
  padding-left: var(--a-cookieBannerWidget-text-padding-left);
}

.cookiesMainText a {
  color: var(--a-cookieBannerWidget-link-color);
}

.cookiesMainButtons {
  margin-top: 16px;
}

/* Media query to target tablet and desktop */
@media all and (min-width:751px) {
  .cookiesMainButtons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.cookiesButtonAccept,
.cookiesButtonReject,
.cookiesButtonSettingsText {
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: inherit;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  padding: 8px 12px;
}

.cookiesButtonAccept:hover,
.cookiesButtonAccept:active,
.cookiesButtonAccept:focus {
  background-color: var(--a-cookieBannerWidget-acceptAllButton-background-color);
  color: var(--a-cookieBannerWidget-acceptAllButton-color);
  border-color: var(--a-cookieBannerWidget-acceptAllButton-border-color);
  opacity: .8;
}

.cookiesButtonReject:hover,
.cookiesButtonReject:active,
.cookiesButtonReject:focus {
  background-color: var(--a-cookieBannerWidget-rejectAllButton-background-color);
  color: var(--a-cookieBannerWidget-rejectAllButton-color);
  border-color: var(--a-cookieBannerWidget-rejectAllButton-border-color);
  opacity: .8;
}

.cookiesButtonSettings:hover,
.cookiesButtonSettings:active,
.cookiesButtonSettings:focus {
  opacity: .8;
}

.cookiesButtonAccept {
  background-color: var(--a-cookieBannerWidget-acceptAllButton-background-color);
  color: var(--a-cookieBannerWidget-acceptAllButton-color);
  border-color: var(--a-cookieBannerWidget-acceptAllButton-border-color);
  border-width: 1px;
  border-style: solid;
}

.cookiesButtonReject {
  background-color: var(--a-cookieBannerWidget-rejectAllButton-background-color);
  color: var(--a-cookieBannerWidget-rejectAllButton-color);
  border-color: var(--a-cookieBannerWidget-rejectAllButton-border-color);
  border-width: 1px;
  border-style: solid;
}

.cookiesButtonSettings i {
  vertical-align: middle;
}

.cookiesButtonSettings span {
  display: none;
}

.cookiesButtonSettingsText {
  display: none;
}

@media (max-width:500px) {
  .cookiesButtonSettings img {
    width: 30px;
  }
}

.cookiesButtonSettingsIcon {
  display: inline-flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: inherit;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  padding: 8px 12px;
}

/* Media query to target only mobile */
@media (max-width:750px) {
  .cookiesButtonSettings span {
    display: inline-block;
    margin-left: 8px;
    line-height: 16px;
    font-size: 14px;
    font-weight: 500;
  }
}

/* Media query to target only mobile */
@media all and (max-width:750px) {
  .cookiesButtonSettingsIcon {
    background-color: var(--a-cookieBannerWidget-secondaryButton-background-color);
    color: var(--a-cookieBannerWidget-secondaryButton-color);
    border-color: var(--a-cookieBannerWidget-secondaryButton-border-color);
    border-width: 1px;
    border-style: solid;
  }

  .cookiesButtonSettings:hover,
  .cookiesButtonSettings:active,
  .cookiesButtonSettings:focus {
    background-color: var(--a-cookieBannerWidget-secondaryButton-background-color);
    color: var(--a-cookieBannerWidget-secondaryButton-color);
    border-color: var(--a-cookieBannerWidget-secondaryButton-border-color);
    opacity: .8;
  }


  .cookiesButtonSettingsIcon i {
    display: none;
  }
}

.avaiconSettings:before {
  color: var(--a-cookieBannerWidget-secondaryButton-background-color);
  font-family: "avaturegdpr";
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  font-size: 26px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\e800';
}
