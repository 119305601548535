.cookieTypeLabel {
  font-family: var(--a-cookieBannerWidget-label-font-family);
  font-weight: var(--a-cookieBannerWidget-label-font-weight);
  font-size: var(--a-cookieBannerWidget-label-font-size);
  color: var(--a-cookieBannerWidget-label-color);
  margin: 0;
  padding: 0;
  border: 0;
}

.cookieTypeDescription {
  font-family: var(--a-cookieBannerWidget-text-font-family);
  font-weight: var(--a-cookieBannerWidget-text-font-weight);
  font-size: var(--a-cookieBannerWidget-text-font-size);
  color: var(--a-cookieBannerWidget-text-color);
}

.cookieTypeDescription a {
  color: var(--a-cookieBannerWidget-link-color);
}

.cookieTypeDescription p {
  margin: 0;
  padding: 0;
}

.cookieTypeDescription a {
  color: #1063BA;
  font-weight: 600;
}

.cookieTypeDescription a:hover,
.cookieTypeDescription a:active,
.cookieTypeDescription a:focus {
  opacity: .8;
}

.cookieTypeToggle {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--a-cookieBannerWidget-toggle-disabled-background-color);
  -webkit-transition: .4s;
  transition: .4s;
}

.cookieTypeToggle:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .cookieTypeToggle {
  background-color: var(--a-cookieBannerWidget-toggle-enabled-background-color);
}

input:focus + .cookieTypeToggle {
  box-shadow: var(--a-cookieBannerWidget-toggle-enabled-background-color);
}

input:checked + .cookieTypeToggle:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.cookieTypeToggle.round {
  border-radius: 10px;
}

.cookieTypeToggle.round:before {
  border-radius: 50%;
}
