/** @FileTooLarge(382) */
/* ==========================================================================
   COOKIES
   --------------------------------------------------------------------------
   * README

   * RESET
   * ICONS
   * DOCUMENT
   ========================================================================== */

/* README
   ========================================================================== */

/*!
 * Styles on this stylesheet are the Cookie Management Layout default styles.
 */

/* RESET
   ========================================================================== */

.cookies_wrapper html,
.cookies_wrapper body,
.cookies_wrapper div,
.cookies_wrapper span,
.cookies h1,
.cookies h2,
.cookies h3,
.cookies h4,
.cookies h5,
.cookies h6,
.cookies_wrapper p,
.cookies_wrapper a,
.cookies_wrapper em,
.cookies_wrapper img,
.cookies_wrapper small,
.cookies_wrapper strong {
    margin: 0;
    padding: 0;
    border: 0;
}

.cookies h1,
.cookies h2,
.cookies h3,
.cookies h4,
.cookies h5,
.cookies h6 {
    font-size: inherit;
    font-weight: inherit;
}

.cookies_wrapper p {
    font-size: inherit;
    font-weight: inherit;
}

.cookies_wrapper a {
    text-decoration: inherit;
    color: inherit;
}

.cookies_wrapper img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic;
}

.cookies_wrapper :focus {
    outline: none;
}

/* ICONS
   ========================================================================== */
@font-face {
    font-family: 'avaturegdpr';
    src: url('fonts/avaturegdpr.eot?81345062');
    src: url('fonts/avaturegdpr.eot?81345062#iefix') format('embedded-opentype'), url('fonts/avaturegdpr.woff2?81345062') format('woff2'), url('fonts/avaturegdpr.woff?81345062') format('woff'), url('fonts/avaturegdpr.ttf?81345062') format('truetype'), url('fonts/avaturegdpr.svg?81345062#avaturegdpr') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* DOCUMENT
   ========================================================================== */
.cookies.cookies__top {
    bottom: inherit;
    top: 0;
}

.cookies__invisible {
    opacity: 0
}

.cookiesButton:hover {
    -webkit-transition: all 250ms ease-in-out;
    -ms-transition: all 250ms ease-in-out;
    transition: all 250ms ease-in-out;
    outline: none;
}

.cookies a:focus,
.cookies a:active,
.cookies button:focus,
.cookies button:active,
.cookies .cookies__settings__option label input:focus,
.cookies .cookies__settings__option label input:active {
    outline-width: thin;
    outline-style: dotted;
    outline-color: #5E6D81;
}

.cookies__wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1272px;
}

/* Media query to target tablet and desktop */
@media all and (min-width:751px) {
    .cookiesButton+.cookiesButton {
        margin-left: 24px;
    }

}

.cookies__settings {
    overflow-y: auto;
    max-height: unset;
}

.cookies__settings::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

.cookies__settings::-webkit-scrollbar-thumb {
    background: #5a5a5a;
    border-radius: 6px;
}

.cookies__settings::-webkit-scrollbar-thumb:active {
    background-color: #5a5a5a;
}

.cookies__settings::-webkit-scrollbar-thumb:hover {
    background: #5a5a5a;
}

.cookies__settings::-webkit-scrollbar-track {
    background: #000;
    border-radius: 6px;
}

.cookies__settings::-webkit-scrollbar-track:hover,
.cookies__settings::-webkit-scrollbar-track:active {
    background: #000;
}

.cookies__settings,
.cookies__settings__buttons {
    display: none;
}

.cookies__settings__content {
    flex-wrap: wrap;
    margin-top: -17px;
    width: 45%;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.cookies__settings__option {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 17px;
}

.cookies__settings__option__title * {
    float: left;
}

.cookies__settings__option__title {
    margin-bottom: 4px;
}

.cookies__settings__option__switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-right: 12px;
}

.cookies__settings__option__switch input[type="checkbox"] {
    width: 100%;
    height: 100%;
    padding: 0;
}

.cookies__readmore__text span {
    display: none;
}

.cookies__readmore__link {
    display: inline-block;
}

.cookies__settings__buttons {
    padding-top: 24px;
    border-top: solid 1px #51555c;
    margin-top: 24px;
}

.cookies__open {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    padding-top: 24px;
    padding-bottom: 24px;
    max-height: calc(100vh - var(--header--height, 10vh));
    outline: none;
}

.cookies__open .cookies__wrapper {
    align-self: center;
}

.cookies__open .cookies__info {
    padding-bottom: 24px;
    border-bottom: solid 1px #51555c;
    margin-bottom: 24px;
}

.cookies__open .cookies__settings,
.cookies__open .cookies__settings__buttons {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.cookies__open .cookies__settings {
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
}

.cookies__open .cookies__settings.cookies__one__column {
    -ms-flex-direction: column;
    flex-direction: column;
}

.cookies__open .cookies__settings.cookies__one__column .cookies__settings__content {
    width: 100%;
}

.cookies__open .cookies__settings.cookies__one__column .cookies__settings__content:last-child {
    margin-top: 0;
}

.cookies__open .cookies__settings__buttons {
    flex-direction: row-reverse;
}

.cookies-warning .link {
    color: #1063BA;
}


/* Media query to target desktop (Laptop) */
@media screen and (max-width:1366px) and (max-height:768px) {
    .cookies__settings__option {
        max-height: unset;
    }

    .cookies__open .cookies__settings {
        padding-top: 0;
    }

}

/* Media query to target mobile and tablet */
@media (max-width:1024px) {
    .cookies {
        padding-top: 24px;
        padding-bottom: 24px;
    }

    .cookies__open .cookies__wrapper {
        align-self: flex-start;
    }

    .cookies__open .cookies__settings {
        padding-right: 10px;
    }

}

/* Media query to target only mobile */
@media all and (max-width:750px) {
    .cookies__wrapper {
        padding-right: 16px;
        padding-left: 16px;
    }

}

/* Media query to target only mobile */
@media (max-width:750px) {
    .cookies {
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .cookies__info,
    .cookies__settings__content {
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .cookiesButton {
        text-align: center;
        width: 100%;        /* display: block; */
    }

    .cookiesButton+.cookiesButton {
        margin-top: 16px;
    }

    .cookies__open .cookies__info {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: solid 1px #51555c;
    }

    .cookies__open .cookies__settings {
        padding-top: 0;
        overflow-y: scroll;
        height: auto;
        border: 0;
        margin-top: 0;
        display: block;
    }

    .cookies__info,
    .cookies__settings__content {
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
    }

    .cookies__settings__content {
        margin-top: -16px;
    }

    .cookies__settings__content:last-child {
        margin-top: 0;
    }

    .cookies__settings__option {
        width: 100%;
        padding-top: 0;
        margin-bottom: 16px;
    }

    .cookies__settings__option:last-child {
        margin-bottom: 0;
    }

    .cookies__settings__buttons {
        padding-top: 16px;
        margin-top: 16px;
    }
}

/* Media query to target tablet and desktop */
@media all and (min-width:751px) {
    .cookies__wrapper {
        padding-right: 32px;
        padding-left: 32px;
    }

}

@media (max-width:500px) {
    .cookies__open .cookies__wrapper {
        align-self: flex-start;
    }
}

@media screen and (max-width:750px) and (max-height:667px) {
    .cookies__open .cookies__settings {
        padding-top: 0;
        height: auto;
    }

}

@media screen and (max-width:750px) and (max-height:568px) {
    .cookies__open .cookies__settings {
        padding-top: 0;
        height: auto;
    }

}

/*
 * Hide only visually, but have it available for screen readers:
 */
.visibility--hidden--visually{
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
}

