.cookiesButtonSave {
  background-color: var(--a-cookieBannerWidget-secondaryButton-background-color);
  color: var(--a-cookieBannerWidget-secondaryButton-color);
  border-color: var(--a-cookieBannerWidget-secondaryButton-border-color);
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: inherit;
  float: right;
  cursor: pointer;
}

.cookiesButtonSave:hover,
.cookiesButtonSave:active,
.cookiesButtonSave:focus {
  background-color: var(--a-cookieBannerWidget-secondaryButton-background-color);
  color: var(--a-cookieBannerWidget-secondaryButton-color);
  border-color: var(--a-cookieBannerWidget-secondaryButton-border-color);
  opacity: .8;
}

/* Media query to target only mobile */
@media (max-width:750px) {
  .cookiesButtonSave {
    width: 100%;
    text-align: center;
  }
}
