// @subcomponents: [wrapper,text,link,label,toggle,acceptAllButton,rejectAllButton,secondaryButton]

cookie-banner-widget {

  /** Widget general wrapper */
  --a-cookieBannerWidget-wrapper-background-color: #000000;

  /** Main text */
  --a-cookieBannerWidget-text-font-family: 'Roboto';
  --a-cookieBannerWidget-text-font-weight: 400;
  --a-cookieBannerWidget-text-font-size: 14px;
  --a-cookieBannerWidget-text-color: #ffffff;
  --a-cookieBannerWidget-text-margin-top: 0;
  --a-cookieBannerWidget-text-margin-right: 0;
  --a-cookieBannerWidget-text-margin-bottom: 0;
  --a-cookieBannerWidget-text-margin-left: 0;
  --a-cookieBannerWidget-text-padding-top: 0;
  --a-cookieBannerWidget-text-padding-right: 0;
  --a-cookieBannerWidget-text-padding-bottom: 0;
  --a-cookieBannerWidget-text-padding-left: 0;
  --a-cookieBannerWidget-link-color: #227fff;

  /** Labels */
  --a-cookieBannerWidget-label-font-family: 'Roboto';
  --a-cookieBannerWidget-label-font-weight: 600;
  --a-cookieBannerWidget-label-font-size: 14px;
  --a-cookieBannerWidget-label-color: #fff;

  /** Toggles */
  --a-cookieBannerWidget-toggle-enabled-background-color: #1063ba;
  --a-cookieBannerWidget-toggle-disabled-background-color: #c8c8c8;

  /** Accept all button */
  --a-cookieBannerWidget-acceptAllButton-background-color: #1063ba;
  --a-cookieBannerWidget-acceptAllButton-border-color: #1063ba;
  --a-cookieBannerWidget-acceptAllButton-color: #ffffff;

  /** Reject all button */
  --a-cookieBannerWidget-rejectAllButton-background-color: #000000;
  --a-cookieBannerWidget-rejectAllButton-border-color: #ffffff;
  --a-cookieBannerWidget-rejectAllButton-color: #ffffff;

  /** Secondary buttons */
  --a-cookieBannerWidget-secondaryButton-background-color: #1063ba;
  --a-cookieBannerWidget-secondaryButton-border-color: #1063ba;
  --a-cookieBannerWidget-secondaryButton-color: #ffffff;
}
